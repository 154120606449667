import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

interface HeaderProps {
  children?: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => {
  const router = useRouter();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    document.body.classList[showMenu ? "add" : "remove"](
      "overflow-hidden",
      "lg:overflow-visible"
    );
  }, [showMenu]);

  return (
    <>
      <div className="header absolute top-0 left-0 right-0 w-full z-50 pt-5 pb-3 transition">
        <div className="container px-5 flex justify-between items-center">
          <Link href="/">
            <div className="w-16 md:w-auto h-16 md:h-auto">
              <Image
                className="cursor-pointer"
                src="/images/logo.svg"
                width={102}
                height={102}
                alt="DesignTinkers"
              />
            </div>
          </Link>

          {/* mobile menu */}
          <button
            onClick={() => setShowMenu((val) => !val)}
            className={`relative lg:hidden z-[100] transition ${
              router.pathname == "/" || showMenu ? "text-white" : "text-purple"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              role="img"
              className="w-12 h-12"
              width="32"
              height="32"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 16 16"
            >
              <path
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
              ></path>
            </svg>
          </button>
          {/* /mobile menu */}

          <div
            className={`fixed top-0 w-8/12	bg-purple h-full pt-32 lg:pt-0 lg:right-auto lg:bg-transparent lg:w-auto lg:relative xl:block menu transition-all lg:transition-none ${
              showMenu ? "right-0" : "-right-full"
            }`}
          >
            <ul
              className={`flex flex-col lg:flex-row text-right gap-y-3 text-white ${
                router.pathname !== "/" && "lg:text-purple"
              }`}
            >
              <li onClick={() => setShowMenu(false)}>
                <Link href="/#workshop-programmes">
                  <div
                    className="btnGoTo cursor-pointer  px-7 text-base font-medium"
                    data-id="#workshop-programmes"
                  >
                    Workshop Programmes
                  </div>
                </Link>
              </li>
              <li onClick={() => setShowMenu(false)}>
                <Link href="/#event-gallery">
                  <div
                    className="btnGoTo cursor-pointer px-7 text-base font-medium"
                    data-id="#event-gallery"
                  >
                    Event Gallery
                  </div>
                </Link>
              </li>
              <li onClick={() => setShowMenu(false)}>
                <Link href="/#who-we-are">
                  <div
                    className="btnGoTo cursor-pointer  px-7 text-base font-medium"
                    data-id="#who-we-are"
                  >
                    Who we are
                  </div>
                </Link>
              </li>
              <li onClick={() => setShowMenu(false)}>
                <Link href="/#news-and-features">
                  <div
                    className="btnGoTo cursor-pointer  px-7 text-base font-medium"
                    data-id="#news-and-features"
                  >
                    News & Features
                  </div>
                </Link>
              </li>
              <li onClick={() => setShowMenu(false)}>
                <Link href="/#contact-us">
                  <div
                    className="btnGoTo cursor-pointer  px-7 text-base font-medium"
                    data-id="#contact-us"
                  >
                    Contact Us
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};
