import { AxiosResponse, CancelToken } from "axios";
import { EnquiryFormValues } from "../components/home/Enquiry";
import { MailingListFormValues } from "../components/home/MailingList";
import http from "../http_clients/designtinkers";
import { Discount } from "../types/Discount";
import { Event } from "../types/Event";
import { EventGallery } from "../types/EventGallery";
import { EventRegisterationResponse } from "../types/EventRegisterationResponse";
import {
  Customer,
  EventRegistration,
  Ticket
} from "../types/EventRegistration";
import { InvitationResponse } from "../types/InvitationResponse";
import { News } from "../types/News";
import { EventGalleryPaginate } from "./../types/EventGalleryPaginate";
import { EventPaginate } from "./../types/EventPaginate";
import { NewsPaginate } from "./../types/NewsPaginate";

interface GetEventGalleryListProps {
  pageSize?: number;
  page?: number;
  orderBy?: string;
  orderFlow?: string;
}

const getEventGalleryList = ({
  pageSize,
  page = 1,
  orderBy = "happening_at",
  orderFlow = "desc",
}: GetEventGalleryListProps): Promise<AxiosResponse<EventGalleryPaginate>> => {
  return http.get("/api/event-gallery", {
    method: "GET",
    params: {
      pageSize: pageSize,
      page: page,
      orderBy: orderBy,
      orderFlow: orderFlow,
    },
  });
};

interface GetNewsListProps {
  pageSize?: number;
  page?: number;
  orderBy?: string;
  orderFlow?: string;
}

const getNewsList = ({
  pageSize,
  page = 1,
  orderBy = "happening_at",
  orderFlow = "desc",
}: GetNewsListProps): Promise<AxiosResponse<NewsPaginate>> => {
  return http.get("/api/news", {
    method: "GET",
    params: {
      pageSize: pageSize,
      page: page,
      orderBy: orderBy,
      orderFlow: orderFlow,
    },
  });
};

interface SaveTransactionProps {
  scheduleId: number;
  eventId: number;
  numberOfTicket: number;
  first_name: string;
  last_name: string;
  phoneCountryId: number;
  phoneNumber: string;
  email: string;
  discountCode?: string;
  paymentMethodId?: number;
  waitingListToken?: string;
}

const saveTransaction = ({
  scheduleId,
  eventId,
  numberOfTicket,
  first_name,
  last_name,
  phoneCountryId,
  phoneNumber,
  email,
  discountCode,
  paymentMethodId,
  waitingListToken,
}: SaveTransactionProps) => {
  return http.post("/api/transaction", {
    schedule_id: scheduleId,
    number_of_ticket: numberOfTicket,
    event_id: eventId,
    first_name: first_name,
    last_name: last_name,
    phoneCountryId: phoneCountryId,
    phoneNumber: phoneNumber,
    email: email,
    discount_code: discountCode,
    payment_method_id: paymentMethodId,
    waitingListToken: waitingListToken,
  });
};

interface GetEventsProps {
  pageSize?: number;
  page?: number;
  isLadingPage?: boolean;
}

const getEvents = ({
  pageSize,
  page = 1,
  isLadingPage = false,
}: GetEventsProps): Promise<AxiosResponse<EventPaginate>> => {
  return http.get("/api/event", {
    method: "GET",
    params: {
      pageSize: pageSize,
      page: page,
      isLadingPage: isLadingPage,
    },
  });
};

interface CheckDiscountProps {
  discountCode: string;
  numberOfTicket: number;
  eventId: number;
  cancelToken: CancelToken;
}

const checkDiscount = ({
  discountCode,
  numberOfTicket,
  eventId,
  cancelToken,
}: CheckDiscountProps): Promise<
  AxiosResponse<{ status: boolean; data: Discount | null }>
> => {
  return http.post(
    "/api/check-discount",
    {
      discount_code: discountCode,
      number_of_ticket: numberOfTicket,
      event_id: eventId,
    },
    {
      cancelToken: cancelToken,
    }
  );
};

export interface PostWaitingListProps {
  numberOfTicket: number;
  first_name: string;
  last_name: string;
  phoneNumber: string;
  email: string;
  selectedCountryId: number;
  scheduleId: number;
}

const postWaitingList = ({
  numberOfTicket,
  first_name,
  last_name,
  phoneNumber,
  email,
  selectedCountryId,
  scheduleId,
}: PostWaitingListProps) => {
  return http.post("/api/waiting-list", {
    number_of_ticket: numberOfTicket,
    first_name: first_name,
    last_name: last_name,
    phone_number: phoneNumber,
    email: email,
    phone_number_country_id: selectedCountryId,
    schedule_id: scheduleId,
  });
};

const getTestimonialList = () => {
  return http.get("/api/testimonial");
};

const refreshPayNowQRCode = (transactionId: number) => {
  return http.post("/api/refresh-paynow-qrcode", {
    transaction_id: transactionId,
  });
};

const checkTransactionPaymentStatus = (transactionId: number) => {
  return http.post("/api/transaction-payment-status", {
    transaction_id: transactionId,
  });
};

const checkWaitingListToken = (token: string) => {
  return http.get("/api/check-waiting-list-token?token=" + token);
};

const getTermsAndConditions = () => {
  return http.get("/api/terms-and-conditions");
};

const getPrivacyPolicy = () => {
  return http.get("/api/privacy-policy");
};

const uploadPayNowPaymentProof = (data: FormData) => {
  return http.post("/api/upload-paynow-payment-proof", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getHomeWording = () => {
  return http.get("/api/home");
};

interface EventGalleryData {
  data: EventGallery;
}

const getEventGalleryDetail = (
  slug: string
): Promise<AxiosResponse<EventGalleryData>> => {
  return http.get("/api/event-gallery/" + slug);
};

interface NewsData {
  data: News;
}

const getNewsDetail = (slug: string): Promise<AxiosResponse<NewsData>> => {
  return http.get("/api/news/" + slug);
};

interface EventData {
  data: Event;
}

const getEventDetail = (slug: string): Promise<AxiosResponse<EventData>> => {
  return http.get("/api/event/" + slug);
};

interface EventGallerySlug {
  slug: string;
}

const getEventGallerySlugs = (): Promise<AxiosResponse<EventGallerySlug[]>> => {
  return http.get("/api/event-gallery/slugs");
};

interface EventSlug {
  slug: string;
}

const getEventSlugs = (): Promise<AxiosResponse<EventSlug[]>> => {
  return http.get("/api/event/slugs");
};

interface NewsSlug {
  slug: string;
}

const getNewsSlugs = (): Promise<AxiosResponse<NewsSlug[]>> => {
  return http.get("/api/news/slugs");
};

interface RelatedNews {
  data: News[];
}

const getRelatedNews = (slug: string): Promise<AxiosResponse<RelatedNews>> => {
  return http.get("/api/news/" + slug + "/related");
};

interface RelatedEvents {
  data: Event[];
}

const getRelatedEvents = (
  slug: string
): Promise<AxiosResponse<RelatedEvents>> => {
  return http.get("/api/event/" + slug + "/related");
};

const getCSRFCookie = () => {
  return http.get("/sanctum/csrf-cookie");
};

const getCountries = () => {
  return http.get("/api/countries");
};

const postEnquiry = (data: EnquiryFormValues) => {
  return http.post("/api/enquiry", data);
};

interface PostStripeSourceProps {
  transactionId: number;
  sourceId: string;
}

const postStripeSource = ({
  transactionId,
  sourceId,
}: PostStripeSourceProps) => {
  return http.post("/api/stripe-source", {
    transaction_id: transactionId,
    source_id: sourceId,
  });
};

const postMailingList = (data: MailingListFormValues) => {
  return http.post("/api/mailing-list", data);
};

const postEventRegistrationInvite = (
  data: Customer,
  ticketId: number
): Promise<AxiosResponse<InvitationResponse>> => {
  return http.post("/api/event-registration/invite", {
    ...data,
    ticket_id: ticketId,
  });
};

const postEventRegistration = (
  data: Ticket[]
): Promise<AxiosResponse<EventRegisterationResponse>> => {
  return http.post("/api/event-registration/submit", JSON.stringify({ data }));
};

const getCustomerByToken = (
  token: string
): Promise<AxiosResponse<EventRegistration>> => {
  return http.get(`/api/customer-by-token/${token}`);
};

const DesignTinkersService = {
  getEventGalleryList,
  getTestimonialList,
  getNewsList,
  getCountries,
  postEnquiry,
  getHomeWording,
  getCSRFCookie,
  getEventGallerySlugs,
  getEventGalleryDetail,
  getNewsSlugs,
  getNewsDetail,
  getRelatedNews,
  postMailingList,
  getEvents,
  getEventDetail,
  getRelatedEvents,
  getEventSlugs,
  checkDiscount,
  getTermsAndConditions,
  getPrivacyPolicy,
  saveTransaction,
  postWaitingList,
  postStripeSource,
  postEventRegistrationInvite,
  postEventRegistration,
  getCustomerByToken,
  checkWaitingListToken,
  refreshPayNowQRCode,
  uploadPayNowPaymentProof,
  checkTransactionPaymentStatus,
};

export default DesignTinkersService;
