import axios from "axios";

const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  withCredentials: true,
});

// instance.interceptors.request.use(
//   async function (config) {
//     if (typeof window !== "undefined") {
//       await instance.get(
//         `${process.env.NEXT_PUBLIC_API_URL}/sanctum/csrf-cookie`
//       );
//     }
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

export default instance;
