import Link from "next/link";

export const Footer = () => {
  return (
    <div className="footer text-xs md:text-sm text-black text-center py-5 bg-green3 relative">
      <div className="container px-5 flex flex-col md:flex-row items-center justify-center gap-0 md:gap-2">
        <div className="flex items-center justify-center">
          <Link href="/terms-and-conditions">
            <div id="mailinglist" className="cursor-pointer">
              Terms & Conditions
            </div>
          </Link>
          <span className="mx-2">|</span>
          <Link href="/privacy-policy">Privacy Policy</Link>
        </div>  
        <span className="hidden md:inline-block">|</span>
        <div>
         © 2021 Design Tinkers Pte. Ltd.
        </div>
      </div>
    </div>
  );
};
