interface PageHeadingProps {
  title?: string;
  subtitle?: string;
}

const PageHeading = ({ title, subtitle }: PageHeadingProps) => {
  if (title === undefined) {
    return null;
  }
  return (
    <div className="pt-[6.5rem] md:pt-36">
      <div
        id="page-heading"
        className={`bg-purple py-8 bg-no-repeat bg-right bg-cover lg:bg-contain decoration-heading ${title === 'News & Features' && 'hidden md:block'}`}
      >
        <div className="container px-5">
          <div className="w-full lg:w-9/12">
            <h3 className="text-white text-3xl font-semibold">{title}</h3>
            {
              subtitle && (
                <p className="text-sm text-white mt-2">{subtitle}</p>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageHeading;
