import Head from "next/head";
import { useRouter } from "next/router";
import { GoogleAnalytics } from "../GoogleAnalytics";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { Meta } from "./Meta";
import PageHeading from "./PageHeading";

interface MainLayoutProps {
  children: React.ReactNode;
  headerChildren?: React.ReactNode;
  pageTitle?: string;
  pageSubtitle?: string;
}

export const MainLayout = ({
  children,
  headerChildren,
  pageTitle,
  pageSubtitle,
}: MainLayoutProps) => {
  const router = useRouter();

  return (
    <>
      <Head>
        <Meta />
      </Head>
      <GoogleAnalytics />
      <Header>{headerChildren}</Header>
      <div className="min-h-screen flex flex-col">
        <div className="flex-grow">
          {router.pathname !== "/event/[slug]" && (
            <PageHeading title={pageTitle} subtitle={pageSubtitle} />
          )}
          {children}
        </div>
        <Footer />
      </div>
    </>
  );
};
