export const Meta = () => {
  return (
    <>
      <meta name="title" content="DesignTinkers" key="title" />
      <meta name="keywords" content="DesignTinkers" />
      <meta
        name="description"
        content="DesignTinkers recognize the child's unique ways of seeing the world. We want them to be inquisitive of what works and what does not in their environment and to instill critical thinking at an early age. By exposing children to design concepts, principles and aesthetics, we introduce design as a process to problem solve and encourage our young ones to be creative in finding solutions."
      />
    </>
  );
};
